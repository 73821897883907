<template>
    <div class="fin-reg">
        <div class="reg-title">
            <div class="reg-ch-title">小程序代码发布</div>
            <div class="reg-en-title">Mini Program Code Release</div>
        </div>
        
        <div class="cm-form">
            
            <a-steps class="preview-steps" direction="vertical">
                <a-step status="finish" title="注册" description="小程序基础."/>
                <a-step status="finish" title="完善信息" description="设置头像与描述."/>
                <a-step status="finish" title="认证" description="将影响本小程序新版本发布和“被搜索”“被分享”能力."/>
                
                <a-step v-if="showBan" :status=" banFinish ? 'finish' : 'process' " title="备案">
                    <template #description>
                        <span>根据政策需求每个小程序都要备案.</span>
                        <template v-if="icpResult && icpResult.status !== 6">
                            <span @click=" $emit('change','ban') " class="text-info cursor-pointer">
                                [{{ icpStatusInfo[`a${icpResult['status']}`] }}]
                            </span>
                            <div>
                                <button @click=" $emit('change','ban') " style="margin-top: 12px;" class="btn btn-sm">
                                    重新备案
                                </button>
                            </div>
                        </template>
                        <span class="text-success" v-else-if="icpResult && icpResult.status === 6">
                            [已备案]
                        </span>
                    </template>
                </a-step>
                
                <a-step title="小程序支付" :status=" payFinish ? 'finish' : 'process' ">
                    
                    <template #description>
                        <span>开通支付后即可进行收款.</span>
                        <span class="text-success" v-if=" payStatus === 'APPLYMENT_STATE_FINISHED' ">
                            [已开通]
                        </span>
                        <span @click=" $emit('change','pay') " class="text-info cursor-pointer"
                              v-else-if=" payStatus !== null ">

                                [{{ payStatusMsgList[payStatus] }}]

                        </span>
                        <span v-else @click=" $emit('change','pay') ">
                            <a class="cursor-pointer">[立即开通]</a>
                        </span>
                    
                    </template>
                
                </a-step>
            
            </a-steps>
            
            <div class="mb-8"></div>
            
            
            <a-button :disabled="!(banFinish && payFinish && !app.rel_version && !app.audit_id)" @click="publish"
                      class="mt-4">小程序发版开通
            </a-button>
        
        </div>
        
        <a-modal v-model="showSuccess" title="小程序发布至微信审核" :maskClosable="false" :closable="false">
            
            <div class="mb-4">预计2~10小时审核成功后，小程序自动发布即可使用。</div>
            <div class="mb-4">现可登录系统后台进行主理人小程序相关资料设置</div>
            
            <div slot="footer">
                <a-button @click="gotoCenter" type="primary">前往主理人后台</a-button>
            </div>
        
        </a-modal>
    
    </div>
</template>

<script>


import api from "@/repo/api";

export default {
    name: "Preview",
    components: {},
    props: {
        app: Object
    },
    data() {
        return {
            showSuccess: false,
            error: '',
            qrcodeUrl: '',
            loading: true,
            params: {
                id: ''
            },
            payStatusMsgList: {
                APPLYMENT_STATE_EDITTING: "提交申请发生错误导致，请尝试重新提交",
                APPLYMENT_STATE_AUDITING: "申请单正在审核中",
                APPLYMENT_STATE_REJECTED: "已驳回,请按照驳回原因修改申请资料",
                APPLYMENT_STATE_TO_BE_CONFIRMED: "待账户验证",
                APPLYMENT_STATE_TO_BE_SIGNED: "待签约",
                APPLYMENT_STATE_SIGNING: "微信系统开通相关权限中",
                APPLYMENT_STATE_FINISHED: "商户入驻申请已完成",
                APPLYMENT_STATE_CANCELED: "申请单已被撤销",
            },
            icpStatusInfo: {
                a2: "微信平台审核中",
                a3: "微信平台审核驳回",
                a4: "政府通讯管理局审核中",
                a5: "政府通讯管理局审核驳回",
                a1024: "未备案",
                a1026: "失败，小程序类目未填",
                a1027: "失败，小程序信息未填",
                a1028: "小程序未认证，请先完成认证",
                a1029: "小程序未认证，请先完成认证",
                a1030: "小程序未认证，请先完成认证",
                a1031: "小程序未认证，请先完成认证",
            },
            payStatus: null,
            icpResult: null,
            timerFlag: null,
            banFinish: false,
            payFinish: false,
        }
    },
    mounted() {
        if(this.showBan) {
            this.getBanStatus(true)
        }
        this.getPayStatus()
    },
    destroyed() {
        if (this.timerFlag) {
            clearTimeout(this.timerFlag)
        }
    },
    computed: {
        showBan() {
            return this.app.customer_type !== 14;
        }
    },
    methods: {
        getPayStatus() {
            
            api.get('/pay-apply/apply-status', (data) => {
                
                if (!data.data) return;
                
                this.payFinish = (data.data.applyment_state === 'APPLYMENT_STATE_FINISHED')
                this.payStatus = data.data.applyment_state
            })
        },
        gotoCenter() {
            this.$router.push('/ast/xcx/base')
        },
        publish() {
            this.$loading.show()
            
            api.post('/ast-reg/publish', {app_id: this.app.id}, data => {
                
                this.$loading.hide()
                
                if (data.code !== 0) return this.$message.error(data.msg)
                
                this.showSuccess = true
            })
            
        },
        getBanStatus(repeat) {
            
            api.get('/ast-reg/ban-status', {app_id: this.app.id}, data => {
                
                if (data.code !== 0) return this.$message.error(data.msg)
                
                this.icpResult = data.data
                
                this.banFinish = (this.icpResult.status === 6)
                
                if ([3, 5, 6].indexOf(this.icpResult.status) >= 0) return;
                
                
                if (repeat) this.timerFlag = setTimeout(() => {
                    
                    this.getBanStatus(repeat)
                    
                }, 15000)
            })
            
        }
    }
}
</script>

<style lang="less" scoped>
.fin-reg-title {
    width: 121px;
    height: 64px;
    background-image: url("/assets/icons/reg-fs-title.png");
    background-size: 121px;
}

.fs-form {
    width: 470px;
}

.qrcode {
    margin-top: 27px;
    padding-top: 32px;
    padding-bottom: 24px;
    text-align: center;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}

.qrcode-img {
    width: 156px;
    height: 156px;
    border: 1px solid #333333;
    margin-bottom: 16px;
    
    img {
        width: 100%;
    }
    
    p {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        margin-bottom: 0;
    }
}

.submit-box {
    margin-top: 24px;
    margin-bottom: 72px;
}

.psf-title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-bottom: 16px;
    
    a {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        float: right;
        text-decoration: underline;
    }
}

.img-modal {
    /deep/ .ant-modal {
        top: 0;
        width: 100vw !important;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.fs-form {
    position: relative;
}

.c-text-1 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-top: 32px;
}

.c-img-1 {
    margin-top: 32px;
    width: 320px;
}

.c-img-2 {
    position: absolute;
    left: 219px;
    top: 386px;
    width: 44px;
}

.c-text-2 {
    margin-top: 48px;
}

.preview-steps {
    /deep/ .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #2cc22c;
        border-color: #2cc22c;
    }
    
    /deep/ .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: white;
    }
}
</style>